import React from 'react'
import SEOTools from '../components/SEOTools'
import { graphql, Link } from 'gatsby'
import { Col, Container, Jumbotron, Row } from 'react-bootstrap'
import { GatsbyImage, getImage, getSrc, StaticImage } from 'gatsby-plugin-image'
import StaticTopNavBarLite from '../components/StaticTopNavBarLite'
import GDPRCookieConsent from '../components/GDPRCookieConsent'

/**
 * Almost a copy of the starteco -page, but with minor differences, targeted for new users arriving from google ads.
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const StartEcoPage = ({ data }) => {
    const heroBg = getImage(data.heroBg)
    const contactBg = getImage(data.contactBg)

    return (
        <>
            <SEOTools
                title='Start using Siru with Ecopayz!'
                description='To pay for your purchases with mobile in Sweden, start using Siru together with Ecopayz!'
                image={getSrc(data.heroBg.childImageSharp)}>
                <script>
                    {`(function(m, a, i, l, e, r) {
                        m['MailerLiteObject'] = e;
                        function f(){
                            var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);
                        }
                        f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
                        var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
                        _.parentNode.insertBefore(r,_);
                    })(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');

                    var ml_account = ml('accounts', '2156928', 'q7l3b3y5n5', 'load');`}
                </script>
            </SEOTools>

            <GDPRCookieConsent />

            <StaticTopNavBarLite />

            <section className='dark-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0 pb-3'>
                    <Container>
                        <Row className='py-5 my-5 d-flex justify-content-center'>
                            <Col md='7'>
                                <h2 className='text-uppercase'>Welcome!</h2>
                                <p className='spaced-light mb-5'>
                                    To pay for your purchases with mobile in Sweden, start using Siru together with
                                    Ecopayz!
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={heroBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='eager'
                    />
                </div>
            </section>

            <Jumbotron id='eco-register-section' fluid className='text-center mb-0'>
                <Container>
                    <Row className='py-4 d-flex justify-content-center'>
                        <Col md='10'>
                            <div className='mb-3 mt-4'>
                                <StaticImage
                                    src='../images/logos/ecoPayz-logo.png'
                                    alt='ecopayz-logo'
                                    placeholder='blurred'
                                    width={200}
                                />
                            </div>
                            <p className='spaced-light mb-5 px-5'>
                                ecoPayz is a safe and secure online payment platform which gives you the freedom to make
                                payments worldwide without worrying about your security and privacy. So you can now
                                easily make payments with your phone anywhere where Mastercard* is accepted, backed by
                                Siru Mobile - no more missed opportunities!
                            </p>
                            <Link to='/startecoprereg' className='btn btn-success btn-lg px-5 rounded-0'>
                                Open a free account
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

            <hr />

            <Jumbotron fluid className='text-center mb-0 pb-0 pt-3'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col md='8'>
                            <div className='mb-5'>
                                <StaticImage
                                    src='../images/startecopayz/ecopayz_app.png'
                                    alt='ecopayz-logo'
                                    placeholder='blurred'
                                    width={400}
                                />
                            </div>
                            <h2 className='text-uppercase'>Key benefits</h2>
                            <div className='spaced-light mb-5 text-left'>
                                <ul>
                                    <li>
                                        With an ecoPayz account receive, send and spend money worldwide all on one
                                        online account.
                                    </li>
                                    <li>
                                        Siru payments work now in over 50,000 different locations via the ecoPayz
                                        Mastercard.
                                    </li>
                                    <li>Instant deposits and withdrawals - no monthly fee, no interest rate!</li>
                                    <li>
                                        It’s free and easy to open an account in minutes, without a bank account or
                                        credit check.
                                    </li>
                                </ul>
                            </div>
                            <div className='mb-5'>
                                <Link to='/startecoprereg' className='btn btn-success btn-lg px-5 rounded-0'>
                                    Open a free account
                                </Link>
                            </div>
                            <p className='text-muted small'>
                                * ecoPayz Mastercard is available to ecoPayz Account Silver level holders and above,
                                subject to our ecoPayz Mastercard Terms and Conditions, in the UK and most of EU.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

            <hr />

            <Jumbotron fluid className='text-center mb-0 bg-white py-4'>
                <Container>
                    <Row className='py-5 my-5 d-flex justify-content-center'>
                        <Col lg='2' md='2' xs='4'>
                            <div className='mb-3 pt-3 pt-sm-0'>
                                <StaticImage
                                    src='../images/people/jasper.jpg'
                                    className='rounded-circle'
                                    alt='logo'
                                    placeholder='blurred'
                                    width={100}
                                />
                            </div>
                        </Col>
                        <Col md='6' xs='8' className='text-left'>
                            <p className='spaced-light'>
                                "I just made my first payments using Siru Mobile and ecoPayz. I can definitely recommend
                                it."
                            </p>
                            <p className='spaced-light mb-4'>
                                <em> - Jasper from Stockholm</em>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

            <hr />

            <Jumbotron fluid className='text-center mb-0'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col md='10'>
                            <h2 className='text-uppercase'>Get started now</h2>
                            <div className='d-flex justify-content-center'>
                                <div className='spaced-light mb-4 text-left'>
                                    <ol>
                                        <li>Open an ecoPayz account (it only takes a few minutes)</li>
                                        <li>Fund your account with Siru Mobile</li>
                                        <li>Spend at millions of locations worldwide</li>
                                    </ol>
                                </div>
                            </div>

                            <Link to='/startecoprereg' className='btn btn-success btn-lg px-5 rounded-0'>
                                Open a free account
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

            <hr />

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center text-center py-5 mb-5'>
                        <Col xs='10' className='mt-5'>
                            <Row className='d-flex align-items-center py-4'>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/comeon.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/videoslots.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={70}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/slot.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={90}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/betsson.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/bethard.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={70}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/getlucky.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <hr />

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center text-center py-5 mb-5'>
                        <Col xs='10' className='mt-5'>
                            <h2 className='text-uppercase mb-5'>Use in everyday and familiar places</h2>
                            <StaticImage
                                src='../images/startecopayz/casinologos/section1/grocery.jpg'
                                alt='grocery'
                                placeholder='blurred'
                                className='border mb-4'
                                height={300}
                            />
                            <Row className='d-flex align-items-center justify-content-center py-4'>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section1/willys.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={80}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section1/coop.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={40}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section1/pressbyran.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={70}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section1/hemkop.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section1/ica.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={40}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <hr />

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center text-center py-5 mb-5'>
                        <Col xs='10' className='mt-5'>
                            <StaticImage
                                src='../images/startecopayz/casinologos/section2/fillerup.jpg'
                                alt='grocery'
                                placeholder='blurred'
                                className='border mb-4'
                                height={300}
                            />
                            <Row className='d-flex align-items-center justify-content-center py-4'>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section2/st1.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section2/shell.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section2/preem.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section2/circle.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section2/ok.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <hr />

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center text-center py-5 mb-5'>
                        <Col xs='10' className='mt-5'>
                            <StaticImage
                                src='../images/startecopayz/casinologos/section3/coffee.jpg'
                                alt='grocery'
                                placeholder='blurred'
                                className='border mb-4'
                                height={300}
                            />
                            <Row className='d-flex align-items-center justify-content-center py-4'>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/foodora.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/m.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/wolt.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/subway.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/7eleven.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col sm='4' xs='6' className='mb-3'>
                                    <StaticImage
                                        src='../images/startecopayz/casinologos/section3/eats.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='darker-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0'>
                    <Container>
                        <Row className='py-5 my-5 justify-content-center'>
                            <Col md='10'>
                                <div
                                    className='ml-form-embed'
                                    data-account='2156928:q7l3b3y5n5'
                                    data-form='5674787:y2p3r2'
                                />
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={contactBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='lazy'
                    />
                </div>
            </section>
        </>
    )
}

export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "products/sirupay/sirupay_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
        contactBg: file(relativePath: { eq: "sirumobile_contact_us.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

export default StartEcoPage
